import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isNavbarCollapsed = true;

  constructor() { }

  ngOnInit() {
  }

  scrollToElement(element): void {
    this.isNavbarCollapsed = true;
    element.scrollIntoView({behavior: 'smooth', inline: 'nearest'});
  }

}
